import { graphql } from "gatsby";
import React from "react";

export default function Mieten({ data }) {
  return <></>;
}

export const query = graphql`
  query {
    queryMieten: allSanityMieten(filter: { language: { eq: "de" } }) {
      nodes {
        carousselType
        id
        manuscript {
          asset {
            url
          }
        }
        pdfUrl
        images {
          asset {
            fluid(maxWidth: 1000) {
              ...GatsbySanityImageFluid
              base64
            }
          }
        }
        place
        _rawText(resolveReferences: { maxDepth: 10 })
        title
      }
    }
    seo: allSanitySeo(
      filter: { title: { eq: "Mieten" }, language: { eq: "de" } }
    ) {
      nodes {
        id
        image {
          asset {
            fluid(maxWidth: 700) {
              ...GatsbySanityImageFluid
              base64
            }
          }
        }
        title
        titlePage
        description
      }
    }
    queryShowHidePlaces: allSanityShowPlacesProgramm {
      nodes {
        klubShowProgramm
        klubShowLocation
        klubShowMieten
        saalShow
        wiengartenShow
        biergartenShow
      }
    }
  }
`;
